import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const StyledAbout = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15vh;

    h1 {
        margin-bottom: 5vh;
    }
    .content {
        width: 70%;
        padding-bottom: 15vh;
    }
    .text {
        font-size: calc(0.6vw + 0.8em);
        line-height: calc(0.9vw + 1.5em);
    }
`;
const About = () => (
  <Layout>
    <StyledAbout>
      <div className="content">
        <h1>About CancerGxSuite</h1>
        <div className="text">
          CancerGxSuite is an open-source software ecosystem that
          expands to multiple domains (immortalized cell lines, patient-derived organoids,
          and patient-derived xenografts), to build an foundational framework of
          preclinical research in oncology, toxicology, and radiology. It allows
          the cancer research community to identify the molecular features of cancer
          cells predictive of pharmacological and radiation treatments and related toxicities.
          <p />
          More specifically, CancerGxSuite provides a set of functions and classes in the
          open-source programming language R for domain-specific scientific software
          libraries, namely PharmacoGx, ToxicoGx, RadioGx, and Xeva. CoreGx abstracts
          shared functionality for the suite of software packages, allowing for standardized
          modular data structures, and functions for fitting and plotting of treatment
          response, and making data amenable to statistical and machine learning analysis.
          The broader CancerGxSuite ecosystem includes web-portal access to the underlying
          data (PharmacoDB, ToxicoDB, and XevaDB), as well as a tool for building new
          pharmacogenomic datasets in a transparent and reproducible manner (ORCESTRA).
        </div>
      </div>
    </StyledAbout>
  </Layout>
);

export default About;
